import React from "react";
import { Link } from "gatsby";
import logo from "../img/gecko-logo-white.svg";
import { MenuItems, MenuItemsBurger } from "./MenuItems";

const Navbar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			navBarActiveClass: "",
		};
	}

	toggleHamburger() {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
							navBarActiveClass: "!block",
					  })
					: this.setState({
							navBarActiveClass: "hidden",
					  });
			}
		);
	}

	render() {
		return (
			<div className="nav-container">
				<nav
					className="bg-stone-900 fixed top-0 left-0 right-0 z-10 h-16"
					role="navigation"
					aria-label="main-navigation"
				>
					<div className="gecko-wrapper flex items-center justify-between h-full">
						<div className="navbar-brand">
							<Link to="/" className="navbar-item" title="Logo">
								<img src={logo} alt="The Gecko Project Logo" className="w-28" />
							</Link>
							{/* Hamburger menu */}
							<div
								className={`block md:hidden`}
								data-target="navMenu"
								role="menuitem"
								tabIndex={0}
								onKeyPress={() => this.toggleHamburger()}
								onClick={() => this.toggleHamburger()}
							>
								<span />
								<span />
								<span />
							</div>
						</div>
						<div
							id="navMenu"
							className={`navbar-menu hidden md:block ${this.state.navBarActiveClass}`}
						>
							<div className="flex space-x-4 items-center">
								<MenuItems />
							</div>
						</div>
						<div className=" md:hidden flex items-center justify-center">
							<MenuItemsBurger />
						</div>
					</div>
				</nav>
				<div className="nav-spacer h-16"></div>
			</div>
		);
	}
};

export default Navbar;
