import React from "react";
import { Link as OgLink } from "gatsby";
import { MenuItemsQuery } from "./MenuItemsQuery";
import { Menu } from "@headlessui/react";
import { HiMenu, HiChevronDown } from "react-icons/hi";
import { SiTwitter } from "react-icons/si";
import {
	Link,
	useI18next,
	I18nextContext,
	useTranslation,
} from "gatsby-plugin-react-i18next";

export const MenuItems = () => {
	const menuItems = MenuItemsQuery();
	const { languages, originalPath } = useI18next();
	const context = React.useContext(I18nextContext);
	const { t } = useTranslation();

	return (
		<>
			<Menu as="div">
				<Menu.Button className="navbar-item nav-link">
					{t("common:menu.topics")}
					<HiChevronDown className="-mt-1 ml-1 h-4 w-4 inline-block fill-white" />
				</Menu.Button>
				<Menu.Items className="absolute menu-items bg-stone-900 p-4 mt-2">
					{menuItems.allTopics.edges.map(
						(topic) =>
							topic.node.fields.language === context.language && (
								<Menu.Item>
									<OgLink
										to={topic.node.fields.slug}
										className="navbar-item sub-nav-link"
									>
										{topic.node.frontmatter.title}
									</OgLink>
								</Menu.Item>
							)
					)}
				</Menu.Items>
			</Menu>
			<Menu as="div">
				<Menu.Button className="navbar-item nav-link">
					{t("common:menu.formats")}
					<HiChevronDown className="-mt-1 ml-1 h-4 w-4 inline-block fill-white" />
				</Menu.Button>
				<Menu.Items className="absolute menu-items bg-stone-900 p-4 mt-2">
					{menuItems.allFormats.edges.map(
						(format) =>
							format.node.fields.language === context.language && (
								<Menu.Item>
									<OgLink
										to={format.node.fields.slug}
										className="navbar-item sub-nav-link"
									>
										{format.node.frontmatter.title}
									</OgLink>
								</Menu.Item>
							)
					)}
				</Menu.Items>
			</Menu>
			<Link
				className="navbar-item nav-link"
				to="/about"
				language={context.language}
			>
				{t("common:menu.about")}
			</Link>
			<ul className="languages flex space-x-2 pl-4 items-center border-l-2 border-stone-800 rounded-sm">
				{languages.map((lng) =>
					lng === context.language ? (
						<li
							key={lng}
							className="inline-block navbar-item nav-link text-gecko-red"
						>
							<Link to={originalPath} language={lng} className="">
								{lng}
							</Link>
						</li>
					) : (
						<li key={lng} className="inline-block navbar-item nav-link">
							<Link to={originalPath} language={lng} className="">
								{lng}
							</Link>
						</li>
					)
				)}
			</ul>
			<OgLink
				className="navbar-item nav-link hover:border-none"
				to="https://twitter.com/geckoproj"
			>
				<SiTwitter className="h-4 w-4 ml-4 hover:fill-gecko-red" />
			</OgLink>
		</>
	);
};

export const MenuItemsBurger = () => {
	const menuItems = MenuItemsQuery();
	const context = React.useContext(I18nextContext);
	const { t } = useTranslation();
	const { languages, originalPath } = useI18next();

	return (
		<>
		<ul className="languages block space-x-2 pr-4 items-center border-r-2 border-stone-800 rounded-sm">
						{languages.map((lng) =>
							lng === context.language ? (
								<li
									key={lng}
									className="inline-block navbar-item nav-link text-gecko-red"
								>
									<Link to={originalPath} language={lng} className="">
										{lng}
									</Link>
								</li>
							) : (
								<li key={lng} className="inline-block navbar-item nav-link">
									<Link to={originalPath} language={lng} className="">
										{lng}
									</Link>
								</li>
							)
						)}
					</ul>
			<Menu as="div" className="h-8">
				<Menu.Button className="">
					<HiMenu
						className="-mr-1 ml-2 h-8 w-8 fill-white hover:fill-gecko-red"
						aria-hidden="true"
					/>
				</Menu.Button>

				<Menu.Items className="absolute right-2 menu-items bg-stone-900 p-4 mt-2">
					
					<Menu.Item
						as="String"
						className="navbar-item nav-link border-b-gecko-red py-2"
					>
						{t("common:menu.topics")}
					</Menu.Item>
					{menuItems.allTopics.edges.map(
						(topic) =>
							topic.node.fields.language === context.language && (
								<Menu.Item>
									<OgLink
										to={topic.node.fields.slug}
										className="navbar-item sub-nav-link"
									>
										{topic.node.frontmatter.title}
									</OgLink>
								</Menu.Item>
							)
					)}
					<Menu.Item
						as="String"
						className="navbar-item nav-link border-b-gecko-red py-2"
					>
						{t("common:menu.formats")}
					</Menu.Item>
					{menuItems.allFormats.edges.map(
						(format) =>
							format.node.fields.language === context.language && (
								<Menu.Item>
									<OgLink
										to={format.node.fields.slug}
										className="navbar-item sub-nav-link"
									>
										{format.node.frontmatter.title}
									</OgLink>
								</Menu.Item>
							)
					)}
					<Menu.Item
						as="String"
						className="navbar-item nav-link border-b-gecko-red py-2"
					>
						<Link to="/about">{t("common:menu.about")}</Link>
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</>
	);
};

export default MenuItemsBurger;

export const FooterMenu = () => {
	const menuItems = MenuItemsQuery();
	const context = React.useContext(I18nextContext);
	const { t } = useTranslation();

	return (
		<>
			<ul className="col-span-full md:col-span-2 pt-4 md:pt-0 px-0 md:px-4">
				<li className="navbar-item nav-link border-b-gecko-red pb-2">
					{t("common:menu.topics")}
				</li>
				{menuItems.allTopics.edges.map(
					(topic) =>
						topic.node.fields.language === context.language && (
							<li>
								<OgLink
									to={topic.node.fields.slug}
									className="navbar-item sub-nav-link"
								>
									{topic.node.frontmatter.title}
								</OgLink>
							</li>
						)
				)}
			</ul>
			<ul className="col-span-full md:col-span-2 pt-4 md:pt-0 px-0 md:px-4">
				<li className="navbar-item nav-link border-b-gecko-red pb-2">
					{t("common:menu.formats")}
				</li>
				{menuItems.allFormats.edges.map(
					(format) =>
						format.node.fields.language === context.language && (
							<li>
								<OgLink
									to={format.node.fields.slug}
									className="navbar-item sub-nav-link"
								>
									{format.node.frontmatter.title}
								</OgLink>
							</li>
						)
				)}
			</ul>
			<ul className="col-span-full md:col-span-2 pt-4 md:pt-0 px-0 md:px-4">
				<li className="navbar-item nav-link border-b-gecko-red pb-2">
					<Link to="/about">{t("common:menu.about")}</Link>
				</li>
			</ul>
		</>
	);
};
