import { graphql, useStaticQuery } from "gatsby";

export const MenuItemsQuery = () => {
	const menuItems = useStaticQuery(graphql `
  query menuItemsQuery {
    allTopics:allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "topics"}}}) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            language
          }
        }
      }
    }
    allFormats:allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "formats"}}}) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            language
          }
        }
      }
    }
  }
  `);

	return menuItems;
};
