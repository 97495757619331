import * as React from "react";
// import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FooterMenu } from "./MenuItems";
import logo from "../img/gecko-logo-white.svg";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className="bg-black px-12 py-12 text-white font-sans grid grid-cols-12 mt-12">
			<div className="col-span-full md:col-span-6">
				<img src={logo} alt="The Gecko Project" className="h-24" />
				<div className="pt-8 text-sm font-light">
					<p>{t("common:footer.orgDetails")}</p>
					<p>{t("common:footer.orgNumber")}</p>
					<p>{t("common:footer.orgAddress")}</p>
				</div>
			</div>
			<FooterMenu />
		</footer>
	);
};
export default Footer;
